import React, { useEffect, useState } from "react";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import datajson from "../../test_information.json";
import allData from "../../test_all_data.json";

import Select from "react-select";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Speedometer from "../reuseable/Speedometer";
import LineChats from "../charts/LineChats";
import Getusers from "../auth/Getusers";
import GraphLayout from "../reuseable/GraphLayout";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Home = () => {
  const [selectedOptionsTestName, setselectedOptionsTestName] = useState([]);
  const [selectedTestItem, setselectedTestItem] = useState("W233018");
  const [selectedTestItemDB, setselectedTestItemDB] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [clearData, setclearData] = useState(false);

  const [time, setTime] = useState(0);
  const [currentSpeedIndex, setCurrentSpeedIndex] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState(0); // Initialize currentSpeed as null initially

  const [currentPressureIndex, setcurrentPressureIndex] = useState(0);
  const [currentPressure, setCurrentPressure] = useState(0); // Initialize currentSpeed as null initially
  const [tabCategory,setTabCategory] = useState("burnish_col")
  
  const [currenttorqueIndex, setcurrenttorqueIndex] = useState(0);
  const [currenttorque, setCurrenttorque] = useState(0); // Initialize currentSpeed as null initially


  const [currentdisplacementIndex, setcurrentdisplacementIndex] = useState(0);
  const [currentdisplacement, setCurrentdisplacement] = useState(0); // Initialize currentSpeed as null initially


  const [currentpad_tempIndex, setcurrentpad_tempIndex] = useState(0);
  const [currentpad_temp, setCurrentpadtemp] = useState(0); // Initialize currentSpeed as null initially


  const [currentrotor_tempIndex, setcurrentrotor_tempIndex] = useState(0);
  const [currentrotor_temp, setCurrentrotortemp] = useState(0); // Initialize currentSpeed as null initially
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TestfilterData = datajson.filter(
    (item) => item.TestName === selectedTestItem
  );

  const allFilterData = allData.filter((item) =>item.TestName === selectedTestItem)

  const testNameArray = datajson.map((item) => ({
    value: item.TestName,
    label: item.TestName,
  }));


  const category = allData.map((item) => item.category)
  const uniqueCategories = [...new Set(category)];
  const categoryData = allData.filter((item) => item.category === tabCategory)

  const categorySpeed = categoryData.map((item) =>item.speed)
  const categoryPressure = categoryData.map((item) =>item.pressure)
  const categorytorque = categoryData.map((item) =>item.torque)
  const categorydisplacement = categoryData.map((item) =>item.displacement)
  const categoryfriction = categoryData.map((item) =>item.friction)
  const categoryrotortemp = categoryData.map((item) =>item.rotor_temp)
  const categorypadtemp = categoryData.map((item) =>item.pad_temp)

  
  
  // console.log(categorySpeed)

  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCount((prevCount) => prevCount + 1);
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [count]);

  const handleChangeTest = (selectedOption) => {
    if (!selectedOption) {
      // If no option is selected (i.e., cleared selection)
      setselectedOptionsTestName([]); // Clear selected options
      setselectedTestItem([]); // Clear selected test items
      setselectedTestItemDB([]); // Clear selected test item for the database
      return;
    }

    const selectedValue = selectedOption.value;
    setselectedOptionsTestName([selectedOption]); // Set selected option
    setselectedTestItem(selectedValue); // Set selected test item
    const userGroupObj = { TestName: selectedValue }; // Create object for database
    setselectedTestItemDB([userGroupObj]); // Set selected test item for the database
  };

  // useEffect(() => {
  //   let timerId;

  //   if (isRunning) {
  //     timerId = setInterval(() => {
  //       setTime((prevTime) => prevTime + 1);
  //     }, 1000);
  //   } else {
  //     clearInterval(timerId);
  //   }

  //   return () => clearInterval(timerId);
  // }, [isRunning]);

  useEffect(() => {
    let timerId;

   

    if (isRunning) {
      timerId = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
        // Move to the next speed
        setCurrentSpeedIndex((prevIndex) => prevIndex + 1);
        setcurrentPressureIndex((prevIndex) => prevIndex + 1)
        setcurrenttorqueIndex((prevIndex) => prevIndex + 1)
        setcurrentdisplacementIndex((prevIndex) => prevIndex + 1)
        setcurrentpad_tempIndex((prevIndex) => prevIndex + 1)
        setcurrentrotor_tempIndex((prevIndex) => prevIndex + 1)
      
        // Check if all speeds have been executed
        if (currentSpeedIndex === categorySpeed.length - 1) {
          setIsRunning(false);
          clearInterval(timerId);
        }
        else if (currentPressureIndex === categoryPressure.length - 1) {
          setIsRunning(false);
          clearInterval(timerId);
        }
        else if (currenttorqueIndex === categorytorque.length - 1) {
          setIsRunning(false);
          clearInterval(timerId);
        }
        else if (currentdisplacementIndex === categorydisplacement.length - 1) {
          setIsRunning(false);
          clearInterval(timerId);
        }
        else if (currentpad_tempIndex === categorypadtemp.length - 1) {
          setIsRunning(false);
          clearInterval(timerId);
        }
        else if (currentrotor_tempIndex === categoryrotortemp.length - 1) {
          setIsRunning(false);
          clearInterval(timerId);
        }
        else{
          setCurrentSpeed(categorySpeed[currentSpeedIndex]);
          setCurrentPressure(categoryPressure[currentPressureIndex]);
          setCurrenttorque(categorytorque[currenttorqueIndex]);
          setCurrentdisplacement(categorydisplacement[currentdisplacementIndex]);
          setCurrentpadtemp(categorypadtemp[currentpad_tempIndex]);
          setCurrentrotortemp(categoryrotortemp[currentrotor_tempIndex]);
        

        }
      }, 1000); // Timer runs every second (1000 milliseconds)
    }

    return () => clearInterval(timerId);
  }, [isRunning, currentSpeedIndex,clearData]);

  const toggleTimer = () => {
    setIsRunning((prevState) => !prevState);
    setclearData(false)

  };

  const toggleReset = () => {
    setIsRunning(false)
    setCurrentdisplacement(0)
    setCurrentPressure(0)
    setCurrenttorque(0)
    setCurrentSpeed(0)
    setTime(0)
    setclearData(true)
    setCurrentrotortemp(0)
    setCurrentpadtemp(0)
  }

  
  return (
    <div style={{background:"#1e2130"}} id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />
          <div id="mainHome">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                position:"relative"
              }}
            >
              <div
                style={{
                  display: "none",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  padding: "10px",
                  marginBottom: "1rem",
                }}
              >
                <span style={{ fontSize: "30px", fontWeight: "600" }}>
                  Welcome Back!!
                </span>
                <span
                  style={{
                    fontSize: "14px !important",
                    fontWeight: "normal",
                    display: "grid",
                    justifyContent: "start",
                  }}
                >
                  <Getusers className="namecls" name={true} />
                </span>
              </div>

                  {value === 1 && (
                    <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap:"10px",
                  paddingRight:"1rem",
                  position:"absolute",
                  right:"30px",
                  top:"5px",
                  alignItems:"center",
                  alignContent:"center",
                  zIndex:"9999"
                }}
              >
               
                <button
                  style={{
                    border: "1px solid #bb1ccc",
                    backgroundColor: "transparent",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    borderRadius: "6px",
                    color:"white"
                  }}
                  onClick={toggleTimer}
                >
                  {isRunning ? "Stop" : "Start"}
                </button>

                <button
                  style={{
                    border: "1px solid #bb1ccc",
                    backgroundColor: "transparent",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    borderRadius: "6px",
                    color:"white"

                  }}
                  onClick={toggleReset}
                >
                  Reset
                </button>


                <div style={{ fontSize: "16px", fontWeight: "600", color:"white" }}>
                  Timer: {time}
                </div>
              </div>
                  )}
              
            </div>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                id="tabsidNew"
              >
                <Tab label="Test Specification" {...a11yProps(0)} />
                <Tab label="AI Simulation" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <Box sx={{ width: "100%" }}>
              <CustomTabPanel id="testSpecifications" value={value} index={0}>
                <div
                  id="homePageContent"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2rem 1rem 1rem",
                    paddingLeft: "10px",
                    alignItems: "center",
                    alignContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <div>
                    <div style={{ fontSize: "24px",color:"white" }} className="heading">
                       AI Simulator
                    </div>
                  </div>

                  <div style={{ width: "400px" }}>
                    <Select
                      options={testNameArray}
                      required
                      placeholder="Select Test Types"
                      value={selectedOptionsTestName}
                      onChange={handleChangeTest}
                      className="RlTypesCls"
                    />
                  </div>
                </div>

                <table style={{ width: "100%" }} id="tableIdNew">
                  <thead>
                    <tr>
                      <th>Parameter</th>
                      <th>Values</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TestfilterData.map((dataObject, index) => (
                      <>
                        {Object.entries(dataObject).map(([key, value]) => (
                          <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </CustomTabPanel>

              <CustomTabPanel id="Simulation" value={value} index={1}>
                <div style={{ display: "grid", gap: "5px" }}>
                  <div style={{ padding: "1rem" }}>
                    <ul
                      style={{
                        marginBottom: "0px",
                        gap: "10px",
                      }}
                      className="nav nav-pills"
                      id="pills-tab"
                      role="tablist"
                    >
                      {uniqueCategories.map((category, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <button
                            className={`nav-link itemName ${
                              index === 0 ? "active" : ""
                            }`}
                            id={`pills-${category}-tab`}
                            data-bs-toggle="pill"
                            data-bs-target={`#pills-${category}`}
                            type="button"
                            role="tab"
                            aria-controls={`pills-${category}`}
                            aria-selected={index === 0 ? "true" : "false"}
                            onClick={() => {
                              setTabCategory(category)
                              toggleReset()
                            }}
                          >
                            {category.replace("_", " ").toUpperCase()}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div
                    style={{
                      gap: "15px",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      padding: "1rem",
                    }}
                  >
                    <div className="tab-content" id="pills-tabContent">
                      {uniqueCategories.map((category, index) => (
                        <div
                          className={`tab-pane fade ${
                            index === 0 ? "show active" : ""
                          }`}
                          id={`pills-${category}`}
                          role="tabpanel"
                          aria-labelledby={`pills-${category}-tab`}
                          key={index}
                        >
                          <GraphLayout
                            speed={currentSpeed || 0}
                            pressure={currentPressure || 0}
                            time={time}
                            currenttorque={currenttorque || 0}
                            currentdisplacement={currentdisplacement || 0}
                            isRunning={isRunning}
                            data={categoryfriction.slice(0, 100)}
                            clearData={clearData}
                            currentpad_temp={currentpad_temp}
                            currentrotor_temp={currentrotor_temp}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
            </Box>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
