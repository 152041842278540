import React from 'react'
import ReactSpeedometer from "react-d3-speedometer"

const Speedometer = (props) => {

  

  return (
    <div>
      
      <ReactSpeedometer
      id="speedID"

      width={250}
      height={160}
      maxSegmentLabels={6}
      minValue={props.minValue}
      maxValue={props.maxValue}
      value={props.value}
      needleHeightRatio={0.7}
      ringWidth={47}
      currentValueText={``}
      labelFontSize='16px'
      segmentColors={["orange","green","#bb1ccc","#3d3d3d","red"]}
      paddingHorizontal={10}

      
  />

    </div>
  )
}

export default Speedometer
