import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ isRunning, dataItem,clearData }) => {
  const [series, setSeries] = useState([ 
    {
      name: "Friction",
      data: [
      ]
    },
    {
      name: "Pressure",
      data: [
        
      ]
    },
    {
      name: "Displacement",
      data: [
        
      ]
    }
   ]);

//   useEffect(() => {
//     let interval;

//     if (isRunning) {
//         setSeries([
//             {
//               name: "Friction",
//               data: [
//                 { x: 1, y: 0.1 },
//                 { x: 2, y: 0.6 },
//                 { x: 3, y: 0.1 },
//                 { x: 4, y: 0.5 },
//                 { x: 5, y: 0.1 },
//                 { x: 6, y: 0.1 },
//                 { x: 7, y: 0.4 },
//                 { x: 8, y: 0.3 },
//                 { x: 9, y: 0.6 },
//                 { x: 10, y: 0.2 },
//                 { x: 11, y: 0.8 },
//                 { x: 12, y: 0.7 },
//                 { x: 13, y: 0.5 },
//                 { x: 14, y: 0.4 },
//                 { x: 15, y: 0.3 },
//                 { x: 16, y: 0.6 },
//                 { x: 17, y: 0.2 },
//                 { x: 18, y: 0.8 },
//                 { x: 19, y: 0.7 },
//                 { x: 20, y: 0.5 },
//                 { x: 21, y: 0.4 },
//                 { x: 22, y: 0.3 },
//                 { x: 23, y: 0.6 },
//                 { x: 24, y: 0.2 },
//                 { x: 25, y: 0.8 },
//                 { x: 26, y: 0.7 }
//               ]
//             },
//             {
//               name: "Pressure",
//               data: [
//                 { x: 1, y: 0.5 },
//                 { x: 2, y: 0.1 },
//                 { x: 3, y: 0.35 },
//                 { x: 4, y: 0.2 },
//                 { x: 5, y: 0.8 },
//                 { x: 6, y: 0.2 },
//                 { x: 7, y: 0.4 },
//                 { x: 8, y: 0.13 },
//                 { x: 9, y: 0.26 },
//                 { x: 10, y: 0.32 },
//                 { x: 11, y: 0.48 },
//                 { x: 12, y: 0.17 },
//                 { x: 13, y: 0.25 },
//                 { x: 14, y: 0.34 },
//                 { x: 15, y: 0.43 },
//                 { x: 16, y: 0.16 },
//                 { x: 17, y: 0.32 },
//                 { x: 18, y: 0.28 },
//                 { x: 19, y: 0.57 },
//                 { x: 20, y: 0.35 },
//                 { x: 21, y: 0.24 },
//                 { x: 22, y: 0.33 },
//                 { x: 23, y: 0.16 },
//                 { x: 24, y: 0.22 },
//                 { x: 25, y: 0.18 },
//                 { x: 26, y: 0.97 }
//               ]
//             },
//             {
//               name: "Displacement",
//               data: [
//                 { x: 1, y: 0.10 },
//                 { x: 2, y: 0.40 },
//                 { x: 3, y: 0.5 },
//                 { x: 4, y: 0.20 },
//                 { x: 5, y: 0.80 },
//                 { x: 6, y: 0.15 },
//                 { x: 7, y: 0.4 },
//                 { x: 8, y: 0.31 },
//                 { x: 9, y: 0.62 },
//                 { x: 10, y: 0.23 },
//                 { x: 11, y: 0.81 },
//                 { x: 12, y: 0.72 },
//                 { x: 13, y: 0.53 },
//                 { x: 14, y: 0.41 },
//                 { x: 15, y: 0.32 },
//                 { x: 16, y: 0.63 },
//                 { x: 17, y: 0.21 },
//                 { x: 18, y: 0.82 },
//                 { x: 19, y: 0.73 },
//                 { x: 20, y: 0.51 },
//                 { x: 21, y: 0.42 },
//                 { x: 22, y: 0.31 },
//                 { x: 23, y: 0.61 },
//                 { x: 24, y: 0.21 },
//                 { x: 25, y: 0.82 },
//                 { x: 26, y: 0.71 }
//               ]
//             }
//           ])
//      interval = setInterval(() => {
//       setSeries(prevSeries => {
//         const newSeries = prevSeries.map(serie => {
//           const newData = [...serie.data.slice(1), { x: serie.data[serie.data.length - 1].x + 1, y: Math.random() }];
//           return { ...serie, data: newData };
//         });
//         return newSeries;
//       });
//     }, 1000); // Update every 2 seconds (adjust as needed)
// }
//   else if(clearData){
//     setSeries([ 
//         {
//           name: "Friction",
//           data: [
//           ]
//         },
//         {
//           name: "Pressure",
//           data: [
            
//           ]
//         },
//         {
//           name: "Displacement",
//           data: [
            
//           ]
//         }
//        ])
//   }
//     return () => clearInterval(interval);
//   }, [isRunning,clearData]);

useEffect(() => {
  let interval;

  if (isRunning) {
    setSeries(prevSeries => {
      // Initialize x-value
      let newX = 1;

      // Create new series with updated x-values
      const newSeries = prevSeries.map(serie => {
        // Increment x-value by 1 for each data point
        const newData = Array.from({ length: serie.data.length + 1 }, (_, index) => ({
          x: newX + index,
          y: Math.random()
        }));
        
        // Update x-value for the next series
        newX += 1;

        return { ...serie, data: newData };
      });

      return newSeries;
    });

    interval = setInterval(() => {
      setSeries(prevSeries => {
        const newSeries = prevSeries.map(serie => {
          // Increment x-value by 1 for each data point
          const newData = [...serie.data.slice(1), { x: serie.data[serie.data.length - 1].x + 1, y: Math.random() }];
          return { ...serie, data: newData };
        });
        return newSeries;
      });
    }, 1000); // Update every 1 second
  } else if (clearData) {
    setSeries([
      {
        name: "Friction",
        data: []
      },
      {
        name: "Pressure",
        data: []
      },
      {
        name: "Displacement",
        data: []
      }
    ]);
  }

  return () => clearInterval(interval);
}, [isRunning, clearData]);

  const options = {
    chart: {
      id: 'realtime',
      height: 350,
      type: 'line',
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors:['#bb1ccc','#77B6EA', '#545454'],
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: '',
      align: 'left'
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'category', // Change x-axis type to category
      categories: [], // Initialize categories array
      labels: {
        show: true
      }
    },
    yaxis: {
      max: 1,
      stepSize: 0.1,
      labels: {
        formatter: function (value) {
          return Math.round(value * 100)/100; // Round to the nearest integer
        }
      }
    },
    legend: {
      show: true
    }
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="line" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
