import React from 'react'
import Thermometer from "react-thermometer-component";

const Temp = ({value}) => {
  return (
    <div>
      <Thermometer
        theme="light"
        value={value}
        max="200"
        steps="1"
        format="°C"
        size="normal"
        height="345"
      />
    </div>
  )
}

export default Temp
