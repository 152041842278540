import React from "react";
import Speedometer from "./Speedometer";
import LineChats from "../charts/LineChats";
import Temp from "./Temp";
import RealTimeChart from "./RealTimeChats";

const GraphLayout = (props) => {
  const time = props.time;
  const isRunning = props.isRunning;
  const speed = props.speed;
  const pressure = props.pressure;
  const currenttorque = props.currenttorque;
  const currentdisplacement = props.currentdisplacement;
  const data = props.data;
  const clearData = props.clearData;
  const pad_temp = props.currentrotor_temp;
  const rotor_temp = props.currentpad_temp;

  return (
    <div style={{ display: "grid",width:"100%",gap:"2rem" }}>
      <div style={{ display: "grid", gridTemplateColumns: "100%" }}>
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "23% 23% 23% 23%",
              gap: "2rem",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              width: "100%",
              height: "100%",
            }}
          >
            <div>
              <span
                style={{
                  color: "white",
                  textAlign: "left",
                  width: "100%",
                  paddingBottom: "10px",
                  display: "block",
                }}
              >
                Speed (KM/H)
              </span>

              <div className="itemSpeedo">
                <Speedometer
                  value={speed >= 160 ? 160 : speed}
                  minValue={0}
                  maxValue={160}
                  title="Speed (KM/H)"
                />
              </div>
            </div>

            <div>
              <span
                style={{
                  color: "white",
                  textAlign: "left",
                  width: "100%",
                  paddingBottom: "10px",
                  display: "block",
                }}
              >
                Pressure (psi)
              </span>

              <div className="itemSpeedo">
                <Speedometer
                  value={pressure >= 130 ? 130 : pressure}
                  minValue={0}
                  maxValue={130}
                  title="Pressure (psi)"
                />
              </div>
            </div>

            <div>
              <span
                style={{
                  color: "white",
                  textAlign: "left",
                  width: "100%",
                  paddingBottom: "10px",
                  display: "block",
                }}
              >
                Torque (Nm)
              </span>

              <div className="itemSpeedo">
                <Speedometer
                  value={currenttorque >= 5000 ? 5000 : currenttorque}
                  minValue={0}
                  maxValue={5000}
                  title="Torque (Nm)"
                />
              </div>
            </div>

            <div>
              <span
                style={{
                  color: "white",
                  textAlign: "left",
                  width: "100%",
                  paddingBottom: "10px",
                  display: "block",
                }}
              >
                Displacement (mm)
              </span>

              <div className="itemSpeedo">
                <Speedometer
                  value={currentdisplacement >= 35 ? 35 : currentdisplacement}
                  minValue={0}
                  maxValue={35}
                  title="Displacement (mm)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{marginTop:"2rem",boxShadow:"0px 0px 4px rgba(0,0,0,0.6)",borderRadius:"10px",background:"#f5f5f5"}}>
        <LineChats pad_temp={pad_temp} rotor_temp ={rotor_temp} clearData ={clearData} dataItem = {data} isRunning={isRunning} />
      </div> */}
      <div
        style={{ display: "grid", gridTemplateColumns: "20% 76%", gap: "3rem",width:"100%" }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <div>
            <span
              style={{
                color: "white",
                textAlign: "left",
                width: "100%",
                paddingBottom: "10px",
                display: "block",
              }}
            >
              Pad Temp
            </span>
            <div style={{ justifyContent: "center" }} className="itemSpeedo">
              <Temp value={pad_temp} />
            </div>
          </div>
          <div>
            <span
              style={{
                color: "white",
                textAlign: "left",
                width: "100%",
                paddingBottom: "10px",
                display: "block",
              }}
            >
              Rotor Temp
            </span>
            <div style={{ justifyContent: "center" }} className="itemSpeedo">
              <Temp value={rotor_temp} />
            </div>
          </div>
        </div>

        <div>
          <span
            style={{
              color: "white",
              textAlign: "left",
              width: "100%",
              paddingBottom: "10px",
              display: "block",
              paddingTop: "0rem",
            }}
          >
            Real Time Graph
          </span>
          <div
            style={{
              boxShadow: "0px 0px 4px rgba(0,0,0,0.6)",
              borderRadius: "10px",
              background: "#161A28",
            }}
          >
            <RealTimeChart
              pad_temp={pad_temp}
              rotor_temp={rotor_temp}
              clearData={clearData}
              dataItem={data}
              isRunning={isRunning}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphLayout;
